import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  BorderOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  HomeOutlined,
  StopOutlined,
  DotChartOutlined,
  NodeExpandOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  VerifiedOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  FundProjectionScreenOutlined,
  UsergroupAddOutlined,
  FormatPainterOutlined,
  NotificationOutlined,
  UserOutlined,
  ApartmentOutlined,
  SettingOutlined,
  BorderOuterOutlined,
  AlignCenterOutlined,
  CheckSquareOutlined,
  MedicineBoxOutlined,
  BuildOutlined,
  DeliveredProcedureOutlined,
  ExperimentOutlined,
  ShoppingFilled,
  ShopOutlined,
  FileImageOutlined,
  SafetyCertificateOutlined,
  MoneyCollectOutlined,
  UserSwitchOutlined,
  ExpandOutlined,
  PercentageOutlined,
  CalculatorOutlined,
  AuditOutlined,
  FileProtectOutlined,
  StarOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { ShippingSVG } from "assets/svg/icon";

const extraNavTree = [
  {
    key: "extra",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "sidenav.pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "extra-pages",
        path: `${APP_PREFIX_PATH}/pages`,
        title: "sidenav.pages",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-pages-profile",
            path: `${APP_PREFIX_PATH}/pages/profile`,
            title: "sidenav.pages.profile",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "extra-pages-list",
            path: `${APP_PREFIX_PATH}/pages/user-list`,
            title: "sidenav.pages.userlist",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-invoice",
            path: `${APP_PREFIX_PATH}/pages/invoice`,
            title: "sidenav.pages.invoice",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-pricing",
            path: `${APP_PREFIX_PATH}/pages/pricing`,
            title: "sidenav.pages.pricing",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-faq",
            path: `${APP_PREFIX_PATH}/pages/faq`,
            title: "sidenav.pages.faq",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "extra-pages-setting",
            path: `${APP_PREFIX_PATH}/pages/setting`,
            title: "sidenav.pages.setting",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "extra-auth",
        path: `${AUTH_PREFIX_PATH}`,
        title: "sidenav.authentication",
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-auth-login-1",
            path: `${AUTH_PREFIX_PATH}/login-1`,
            title: "sidenav.authentication.login.1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-login-2",
            path: `${AUTH_PREFIX_PATH}/login-2`,
            title: "sidenav.authentication.login.2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-register-1",
            path: `${AUTH_PREFIX_PATH}/register-1`,
            title: "sidenav.authentication.register.1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-register-2",
            path: `${AUTH_PREFIX_PATH}/register-2`,
            title: "sidenav.authentication.register.2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-forgot-password",
            path: `${AUTH_PREFIX_PATH}/forgot-password`,
            title: "sidenav.authentication.forgetPassword",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "extra-errors",
        path: `${AUTH_PREFIX_PATH}/error-1`,
        title: "sidenav.errors",
        icon: StopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-errors-error-1",
            path: `${AUTH_PREFIX_PATH}/error-1`,
            title: "sidenav.errors.error.1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-errors-error-2",
            path: `${AUTH_PREFIX_PATH}/error-2`,
            title: "sidenav.errors.error.2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-subadmins",
        path: `${APP_PREFIX_PATH}/dashboards/subadmins`,
        title: "Admins & Roles",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-subadmins-list",
            path: `${APP_PREFIX_PATH}/dashboards/subadmins/subadmins-list`,
            title: "Sub Admins",
            permissionKey: "subAdmin",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-subadmins-type",
            path: `${APP_PREFIX_PATH}/dashboards/subadmins/subadminstype`,
            title: "Admin Roles",
            permissionKey: "subAdminType",
            icon: UserSwitchOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      // {
      //   key: 'dashboards-analytic',
      //   path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      //   title: 'sidenav.dashboard.analytic',
      //   icon: DotChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      {
        key: "dashboards-catalog",
        path: `${APP_PREFIX_PATH}/dashboards/catalog`,
        title: "Catalog",
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-catalog-brands",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/brand/brands-list`,
            title: "Brands",
            permissionKey: "brands",
            icon: SafetyCertificateOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-main-banner",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/main-banner/main-banner-list`,
            title: "Main Banner",
            permissionKey: "mainBanner",
            icon: ExpandOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-catalog-banner",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/banner/banner-list`,
            title: "Banner",
            permissionKey: "banner",
            icon: FileImageOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-catalog-category",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/category/category-list`,
            title: "Category",
            permissionKey: "category",
            icon: ApartmentOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-catalog-producttemplate",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/producttemplate/producttemplate-list`,
            title: "Product Template",
            permissionKey: "productTemplate",
            icon: ShopOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-catalog-product",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/product/product-list`,
            title: "Product",
            permissionKey: "product",
            icon: ShoppingFilled,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-catalog-coupon",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/coupon/coupon-list`,
            title: "Coupon",
            permissionKey: "coupon",
            icon: BookOutlined,
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "dashboards-catalog-attribute",
            path: `${APP_PREFIX_PATH}/dashboards/catalog/attribute/attribute-list`,
            title: "Attribute",
            permissionKey: "attribute",
            icon: FundProjectionScreenOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "dashboards-users",
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        title: "Users",
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-users-usergroup",
            path: `${APP_PREFIX_PATH}/dashboards/users/usergroup/usergroup-list`,
            title: "User Group",
            permissionKey: "users-userGroup",
            icon: UsergroupAddOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-users-customers",
            path: `${APP_PREFIX_PATH}/dashboards/users/customer/customer-list`,
            title: "Customer",
            permissionKey: "users-customer",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "dashboards-users-vendors",
            path: `${APP_PREFIX_PATH}/dashboards/users/vendor/vendor-list`,
            title: "Vendor",
            permissionKey: "users-vendor",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "dashboards-information",
        path: `${APP_PREFIX_PATH}/dashboards/information/information-list`,
        title: "Information & Policies",
        permissionKey: "information",
        // icon: DotChartOutlined,
        icon: InfoCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "dashboards-review",
        path: `${APP_PREFIX_PATH}/dashboards/review/review-list`,
        title: "Review",
        permissionKey: "review",
        // icon: DotChartOutlined,
        icon: StarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-notifications",
        path: `${APP_PREFIX_PATH}/dashboards/notifications`,
        title: "Notifications",
        icon: NotificationOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-notifications-template",
            path: `${APP_PREFIX_PATH}/dashboards/notifications/template`,
            title: "Template",
            permissionKey: "notification-template",
            icon: AlignCenterOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-notifications-notificationhistory",
            path: `${APP_PREFIX_PATH}/dashboards/notifications/notification-history`,
            title: "Notification History",
            permissionKey: "notification-notificationHistory",
            icon: NotificationOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      // {
      //   key: 'dashboards-notifications',
      //   path: `${APP_PREFIX_PATH}/dashboards/notifications/notification-list`,
      //   title: 'Notifications',
      //   icon: NotificationOutlined ,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      {
        key: "dashboards-orders",
        path: `${APP_PREFIX_PATH}/dashboards/orders`,
        title: "Orders",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-orders-section",
            path: `${APP_PREFIX_PATH}/dashboards/orders/orders-list`,
            title: "Orders",
            permissionKey: "orders-list",
            icon: ShoppingCartOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-orders-return",
            path: `${APP_PREFIX_PATH}/dashboards/orders/returns-list`,
            title: "Returns",
            permissionKey: "orders-return",
            icon: ShoppingCartOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-orders-refunds",
            path: `${APP_PREFIX_PATH}/dashboards/orders/refunds-list`,
            title: "Refunds",
            permissionKey: "refund",
            icon: ShoppingCartOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      // {
      //   key: "support",
      //   path: `${APP_PREFIX_PATH}/dashboards/support`,
      //   title: "Support",
      //   permissionKey: "support",
      //   icon: MailOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      {
        key: "dashboards-deliverylocation-deliveryzones",
        path: `${APP_PREFIX_PATH}/dashboards/deliverylocation/deliveryzone/deliveryzone-list`,
        title: "Delivery Zones",
        permissionKey: "deliveryZones",
        icon: DeliveredProcedureOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // key: 'dashboards-deliverylocation',
      // path: `${APP_PREFIX_PATH}/dashboards/delivery-location`,
      // title: 'Delivery Location',
      // icon: DeliveredProcedureOutlined,
      // breadcrumb: false,
      // submenu: [
      //   {
      //     key: 'dashboards-deliverylocation-deliveryzones',
      //     path: `${APP_PREFIX_PATH}/dashboards/deliverylocation/deliveryzone/deliveryzone-list`,
      //     title: 'Delivery zones',
      //     icon: DeliveredProcedureOutlined,
      //     breadcrumb: false,
      //     submenu: [],
      //   },
      // ],

      {
        key: "dashboards-locality",
        path: `${APP_PREFIX_PATH}/dashboards/locality`,
        title: "Locality",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [
          // {
          //   key: 'dashboards-locality-country',
          //   path: `${APP_PREFIX_PATH}/dashboards/locality/country/country-list`,
          //   title: 'Country',
          //   icon: AppstoreOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },

          // {
          //   key: 'dashboards-locality-state',
          //   path: `${APP_PREFIX_PATH}/dashboards/locality/state/statelist`,
          //   title:
          //     process.env.REACT_APP_SITE_NAME === 'athathy'
          //       ? 'Emirates'
          //       : 'State',
          //   icon: AppstoreOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
          {
            key: "dashboards-locality-district",
            path: `${APP_PREFIX_PATH}/dashboards/locality/district/districtlist`,
            title:
              process.env.REACT_APP_SITE_NAME === "zapkart"
                ? "District"
                : "Country",
            permissionKey: "district",
            icon: EnvironmentOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-locality-city",
            path: `${APP_PREFIX_PATH}/dashboards/locality/city/city-list`,
            title:
              process.env.REACT_APP_SITE_NAME === "zapkart"
                ? "City"
                : "Emirates",
            permissionKey: "city",
            icon: EnvironmentOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-locality-pincode",
            path: `${APP_PREFIX_PATH}/dashboards/locality/pincode/pincodelist`,
            title:
              process.env.REACT_APP_SITE_NAME === "zapkart"
                ? "Pincode"
                : "City",
            permissionKey: "pincode",
            icon: EnvironmentOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "dashboards-widget",
        path: `${APP_PREFIX_PATH}/dashboards/widget/widget-list`,
        title: "Widget",
        permissionKey: "widget",
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-shipment",
        path: `${APP_PREFIX_PATH}/dashboards/shipments`,
        title: "Shipments",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-shipment-shipment",
            path: `${APP_PREFIX_PATH}/dashboards/shipments/shipment/shipment-list`,
            title: "All Shipments",
            permissionKey: "shipment",
            icon: FormatPainterOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-shipment-pickuplocation",
            path: `${APP_PREFIX_PATH}/dashboards/shipments/pickuplocation/pickuplocation-list`,
            title: "Pickup Locations",
            permissionKey: "pickupLocation",
            icon: NodeExpandOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-transactions",
        path: `${APP_PREFIX_PATH}/dashboards/transactions`,
        title: "Transactions",
        permissionKey: "transaction",
        icon: TransactionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-tax-category",
        path: `${APP_PREFIX_PATH}/dashboards/tax-category`,
        title: "Tax Category",
        permissionKey: "taxCategory",
        icon: PercentageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      /* {
        key: 'dashboards-imagecategories',
        path: `${APP_PREFIX_PATH}/dashboards/image-categories/image-list`,
        title: 'Images',
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
       */
      {
        key: "dashboards-reports",
        path: `${APP_PREFIX_PATH}/dashboards/reports`,
        title: "Report",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-sales-report",
            path: `${APP_PREFIX_PATH}/dashboards/reports/salesreport`,
            title: "Sales",
            permissionKey: "salesReport",
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-shipments-report",
            path: `${APP_PREFIX_PATH}/dashboards/reports/shipmentsreport`,
            title: "Shipments",
            permissionKey: "shipmentReport",
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-accounts-report",
            path: `${APP_PREFIX_PATH}/dashboards/reports/accountsreport`,
            title: "Accounts",
            permissionKey: "accountsReport",
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-vendor-payout",
        path: `${APP_PREFIX_PATH}/dashboards/vendor-payout`,
        title: "Vendor Payout",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-vendor-payout-requests",
            path: `${APP_PREFIX_PATH}/dashboards/vendor-payout/payout-requests`,
            title: "Payout Request",
            permissionKey: "vendorPayoutRequest",
            icon: AuditOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-vendor-payout-reports",
            path: `${APP_PREFIX_PATH}/dashboards/vendor-payout`,
            title: "Payout Calculation",
            permissionKey: "vendorPayoutReport",
            icon: CalculatorOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-vendor-payout-tds-certificate",
            path: `${APP_PREFIX_PATH}/dashboards/vendor-payout/tds-certificate`,
            title: "TDS Certificates",
            permissionKey: "vendorPayoutTdsCertificates",
            icon: FileProtectOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-vendor-payout-invoices",
            path: `${APP_PREFIX_PATH}/dashboards/vendor-payout/invoices`,
            title: "Invoices",
            permissionKey: "vendorPayoutInvoice",
            icon: FileTextOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "dashboards-settings",
        path: `${APP_PREFIX_PATH}/dashboards/settings`,
        title: "Settings",
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// {
//   key: 'dashboards-catalog-medicinetype',
//   path: `${APP_PREFIX_PATH}/dashboards/catalog/medicinetype/medicinetype-list`,
//   title: 'Medicine Type',
//   icon: FormatPainterOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: 'dashboards-catalog-manufacturer',
//   path: `${APP_PREFIX_PATH}/dashboards/catalog/manufacturer/manufacturer-list`,
//   title: 'Manufacturer',
//   icon: FormatPainterOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: 'dashboards-catalog-composition',
//   path: `${APP_PREFIX_PATH}/dashboards/catalog/composition/composition-list`,
//   title: 'Composition',
//   icon: FormatPainterOutlined,
//   breadcrumb: false,
//   submenu: [],
// },

if (process.env.REACT_APP_SITE_NAME === "zapkart") {
  dashBoardNavTree[0].submenu[2].submenu.push({
    key: "dashboards-catalog-medicinetype",
    path: `${APP_PREFIX_PATH}/dashboards/catalog/medicinetype/medicinetype-list`,
    title: "Medicine Type",
    permissionKey: "medicineType",
    icon: MedicineBoxOutlined,
    breadcrumb: false,
    submenu: [],
  });

  dashBoardNavTree[0].submenu[2].submenu.push({
    key: "dashboards-catalog-manufacturer",
    path: `${APP_PREFIX_PATH}/dashboards/catalog/manufacturer/manufacturer-list`,
    title: "Manufacturer",
    permissionKey: "manufacturer",
    icon: BuildOutlined,
    breadcrumb: false,
    submenu: [],
  });

  dashBoardNavTree[0].submenu[2].submenu.push({
    key: "dashboards-catalog-composition",
    permissionKey: "composition",
    path: `${APP_PREFIX_PATH}/dashboards/catalog/composition/composition-list`,
    title: "Composition",
    icon: ExperimentOutlined,
    breadcrumb: false,
    submenu: [],
  });

  // For locality

  const pos = dashBoardNavTree[0].submenu.map(e => e.title).indexOf('Locality');

  dashBoardNavTree[0].submenu[pos].submenu.unshift({
    key: "dashboards-locality-state",
    path: `${APP_PREFIX_PATH}/dashboards/locality/state/statelist`,
    title: "State",
    permissionKey: "state",
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: [],
  });

  dashBoardNavTree[0].submenu[pos].submenu.unshift({
    key: "dashboards-locality-country",
    path: `${APP_PREFIX_PATH}/dashboards/locality/country/country-list`,
    title: "Country",
    permissionKey: "country",
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: [],
  });
}

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-mail",
        path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
        title: "sidenav.apps.mail",
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-chat",
        path: `${APP_PREFIX_PATH}/apps/chat`,
        title: "sidenav.apps.chat",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-calendar",
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        title: "sidenav.apps.calendar",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-project",
        path: `${APP_PREFIX_PATH}/apps/project`,
        title: "sidenav.apps.project",
        icon: BulbOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-project-list",
            path: `${APP_PREFIX_PATH}/apps/project/list`,
            title: "sidenav.apps.project.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-scrumboard",
            path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
            title: "sidenav.apps.project.scrumboard",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "sidenav.apps.ecommerce",
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-ecommerce-productList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
            title: "sidenav.apps.ecommerce.productList",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "apps-ecommerce-addProduct",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
            title: "sidenav.apps.ecommerce.addProduct",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-editProduct",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/12`,
            title: "sidenav.apps.ecommerce.editProduct",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-orders",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
            title: "sidenav.apps.ecommerce.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const componentsNavTree = [
  {
    key: "components",
    path: `${APP_PREFIX_PATH}/components`,
    title: "sidenav.components",
    icon: AntDesignOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "components-general",
        path: `${APP_PREFIX_PATH}/components/general`,
        title: "sidenav.components.general",
        icon: InfoCircleOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-general-button",
            path: `${APP_PREFIX_PATH}/components/general/button`,
            title: "sidenav.components.general.button",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-general-icon",
            path: `${APP_PREFIX_PATH}/components/general/icon`,
            title: "sidenav.components.general.icon",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-general-typography",
            path: `${APP_PREFIX_PATH}/components/general/typography`,
            title: "sidenav.components.general.typography",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-layout",
        path: `${APP_PREFIX_PATH}/components/layout`,
        title: "sidenav.components.layout",
        icon: LayoutOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-layout-grid",
            path: `${APP_PREFIX_PATH}/components/layout/grid`,
            title: "sidenav.components.layout.grid",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-layout-layout",
            path: `${APP_PREFIX_PATH}/components/layout/layout`,
            title: "sidenav.components.layout.layout",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-navigation",
        path: `${APP_PREFIX_PATH}/components/navigation`,
        title: "sidenav.components.navigation",
        icon: CompassOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-navigation-affix",
            path: `${APP_PREFIX_PATH}/components/navigation/affix`,
            title: "sidenav.components.navigation.affix",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-breadcrumb",
            path: `${APP_PREFIX_PATH}/components/navigation/breadcrumb`,
            title: "sidenav.components.navigation.breadcrumb",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-dropdown",
            path: `${APP_PREFIX_PATH}/components/navigation/dropdown`,
            title: "sidenav.components.navigation.dropdown",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-menu",
            path: `${APP_PREFIX_PATH}/components/navigation/menu`,
            title: "sidenav.components.navigation.menu",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-pagination",
            path: `${APP_PREFIX_PATH}/components/navigation/pagination`,
            title: "sidenav.components.navigation.pagination",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-page-header",
            path: `${APP_PREFIX_PATH}/components/navigation/page-header`,
            title: "sidenav.components.navigation.pageHeader",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-steps",
            path: `${APP_PREFIX_PATH}/components/navigation/steps`,
            title: "sidenav.components.navigation.steps",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-data-entry",
        path: `${APP_PREFIX_PATH}/components/data-entry`,
        title: "sidenav.components.dataEntry",
        icon: FileDoneOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-data-entry-auto-complete",
            path: `${APP_PREFIX_PATH}/components/data-entry/auto-complete`,
            title: "sidenav.components.dataEntry.autoComplete",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-checkbox",
            path: `${APP_PREFIX_PATH}/components/data-entry/checkbox`,
            title: "sidenav.components.dataEntry.checkbox",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-cascader",
            path: `${APP_PREFIX_PATH}/components/data-entry/cascader`,
            title: "sidenav.components.dataEntry.cascader",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-date-picker",
            path: `${APP_PREFIX_PATH}/components/data-entry/date-picker`,
            title: "sidenav.components.dataEntry.datePicker",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-form",
            path: `${APP_PREFIX_PATH}/components/data-entry/form`,
            title: "sidenav.components.dataEntry.form",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-input-number",
            path: `${APP_PREFIX_PATH}/components/data-entry/input-number`,
            title: "sidenav.components.dataEntry.inputNumber",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-input",
            path: `${APP_PREFIX_PATH}/components/data-entry/input`,
            title: "sidenav.components.dataEntry.input",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-mentions",
            path: `${APP_PREFIX_PATH}/components/data-entry/mentions`,
            title: "sidenav.components.dataEntry.mentions",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-rate",
            path: `${APP_PREFIX_PATH}/components/data-entry/rate`,
            title: "sidenav.components.dataEntry.rate",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-radio",
            path: `${APP_PREFIX_PATH}/components/data-entry/radio`,
            title: "sidenav.components.dataEntry.radio",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-switch",
            path: `${APP_PREFIX_PATH}/components/data-entry/switch`,
            title: "sidenav.components.dataEntry.switch",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-slider",
            path: `${APP_PREFIX_PATH}/components/data-entry/slider`,
            title: "sidenav.components.dataEntry.slider",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-select",
            path: `${APP_PREFIX_PATH}/components/data-entry/select`,
            title: "sidenav.components.dataEntry.select",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-tree-select",
            path: `${APP_PREFIX_PATH}/components/data-entry/tree-select`,
            title: "sidenav.components.dataEntry.treeSelect",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-transfer",
            path: `${APP_PREFIX_PATH}/components/data-entry/transfer`,
            title: "sidenav.components.dataEntry.transfer",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-time-picker",
            path: `${APP_PREFIX_PATH}/components/data-entry/time-picker`,
            title: "sidenav.components.dataEntry.timePicker",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-upload",
            path: `${APP_PREFIX_PATH}/components/data-entry/upload`,
            title: "sidenav.components.dataEntry.upload",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-data-display",
        path: `${APP_PREFIX_PATH}/components/data-display`,
        title: "sidenav.components.dataDisplay",
        icon: DesktopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-data-display-avatar",
            path: `${APP_PREFIX_PATH}/components/data-display/avatar`,
            title: "sidenav.components.dataDisplay.avatar",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-badge",
            path: `${APP_PREFIX_PATH}/components/data-display/badge`,
            title: "sidenav.components.dataDisplay.badge",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-comment",
            path: `${APP_PREFIX_PATH}/components/data-display/comment`,
            title: "sidenav.components.dataDisplay.comment",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-collapse",
            path: `${APP_PREFIX_PATH}/components/data-display/collapse`,
            title: "sidenav.components.dataDisplay.collapse",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-carousel",
            path: `${APP_PREFIX_PATH}/components/data-display/carousel`,
            title: "sidenav.components.dataDisplay.carousel",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-card",
            path: `${APP_PREFIX_PATH}/components/data-display/card`,
            title: "sidenav.components.dataDisplay.card",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-calendar",
            path: `${APP_PREFIX_PATH}/components/data-display/calendar`,
            title: "sidenav.components.dataDisplay.calendar",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-descriptions",
            path: `${APP_PREFIX_PATH}/components/data-display/descriptions`,
            title: "sidenav.components.dataDisplay.descriptions",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-empty",
            path: `${APP_PREFIX_PATH}/components/data-display/empty`,
            title: "sidenav.components.dataDisplay.empty",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-list",
            path: `${APP_PREFIX_PATH}/components/data-display/list`,
            title: "sidenav.components.dataDisplay.list",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-popover",
            path: `${APP_PREFIX_PATH}/components/data-display/popover`,
            title: "sidenav.components.dataDisplay.popover",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-statistic",
            path: `${APP_PREFIX_PATH}/components/data-display/statistic`,
            title: "sidenav.components.dataDisplay.statistic",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tree",
            path: `${APP_PREFIX_PATH}/components/data-display/tree`,
            title: "sidenav.components.dataDisplay.tree",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tooltip",
            path: `${APP_PREFIX_PATH}/components/data-display/tooltip`,
            title: "sidenav.components.dataDisplay.tooltip",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-timeline",
            path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
            title: "sidenav.components.dataDisplay.timeline",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tag",
            path: `${APP_PREFIX_PATH}/components/data-display/tag`,
            title: "sidenav.components.dataDisplay.tag",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tabs",
            path: `${APP_PREFIX_PATH}/components/data-display/tabs`,
            title: "sidenav.components.dataDisplay.tabs",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-table",
            path: `${APP_PREFIX_PATH}/components/data-display/table`,
            title: "sidenav.components.dataDisplay.table",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-feedback",
        path: `${APP_PREFIX_PATH}/components/feedback`,
        title: "sidenav.components.feedback",
        icon: CommentOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-feedback-alert",
            path: `${APP_PREFIX_PATH}/components/feedback/alert`,
            title: "sidenav.components.feedback.alert",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-drawer",
            path: `${APP_PREFIX_PATH}/components/feedback/drawer`,
            title: "sidenav.components.feedback.drawer",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-modal",
            path: `${APP_PREFIX_PATH}/components/feedback/modal`,
            title: "sidenav.components.feedback.modal",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-message",
            path: `${APP_PREFIX_PATH}/components/feedback/message`,
            title: "sidenav.components.feedback.message",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-notification",
            path: `${APP_PREFIX_PATH}/components/feedback/notification`,
            title: "sidenav.components.feedback.notification",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-progress",
            path: `${APP_PREFIX_PATH}/components/feedback/progress`,
            title: "sidenav.components.feedback.progress",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-popconfirm",
            path: `${APP_PREFIX_PATH}/components/feedback/popconfirm`,
            title: "sidenav.components.feedback.popconfirm",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-result",
            path: `${APP_PREFIX_PATH}/components/feedback/result`,
            title: "sidenav.components.feedback.result",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-spin",
            path: `${APP_PREFIX_PATH}/components/feedback/spin`,
            title: "sidenav.components.feedback.spin",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-skeleton",
            path: `${APP_PREFIX_PATH}/components/feedback/skeleton`,
            title: "sidenav.components.feedback.skeleton",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-other",
        path: `${APP_PREFIX_PATH}/components/other`,
        title: "sidenav.components.other",
        icon: RobotOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-other-anchor",
            path: `${APP_PREFIX_PATH}/components/other/anchor`,
            title: "sidenav.components.other.anchor",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-other-backtop",
            path: `${APP_PREFIX_PATH}/components/other/backtop`,
            title: "sidenav.components.other.backtop",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-other-config-provider",
            path: `${APP_PREFIX_PATH}/components/other/config-provider`,
            title: "sidenav.components.other.configProvider",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-other-divider",
            path: `${APP_PREFIX_PATH}/components/other/divider`,
            title: "sidenav.components.other.divider",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-charts",
        path: `${APP_PREFIX_PATH}/components/charts`,
        title: "sidenav.charts",
        icon: PieChartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-charts-apex",
            path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
            title: "sidenav.charts.apex",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-charts-chartjs",
            path: `${APP_PREFIX_PATH}/components/charts/chartjs`,
            title: "sidenav.charts.chartjs",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-maps",
        path: `${APP_PREFIX_PATH}/components/maps`,
        title: "sidenav.maps",
        icon: EnvironmentOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-maps-google",
            path: `${APP_PREFIX_PATH}/components/maps/google-map`,
            title: "sidenav.maps.google",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-maps-simple",
            path: `${APP_PREFIX_PATH}/components/maps/simple-map`,
            title: "sidenav.maps.simple",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const docsNavTree = [
  {
    key: "docs",
    path: `${APP_PREFIX_PATH}/docs`,
    title: "sidenav.docs",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "docs-documentation",
        path: `${APP_PREFIX_PATH}/docs/documentation`,
        title: "sidenav.docs.documentation",
        icon: FileUnknownOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "docs-changelog",
        path: `${APP_PREFIX_PATH}/docs/documentation/changelog`,
        title: "sidenav.docs.changelog",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  // ...appsNavTree,
  // ...componentsNavTree,
  // ...extraNavTree,
  // ...docsNavTree,
];

export default navigationConfig;
